import "core-js/modules/es.array.join"; // Any plugins you want to use has to be imported
// Detail plugins list see https://www.tinymce.com/docs/plugins/
// Custom builds see https://www.tinymce.com/download/custom-builds/

// eslint-disable-next-line no-unused-vars
var defaultPlugins = ['advlist anchor autolink autosave code codesample colorpicker contextmenu directionality  fullscreen hr image imagetools link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace tabfocus table textcolor textpattern visualblocks visualchars wordcount'];

var list = [
'image', // 插入上传图片插件
'media', // 插入视频插件
'table', // 插入表格插件
'link', // 超链接插件
'code', // 代码块插件
'lists', // 列表插件
'wordcount', // 字数统计插件
'fullscreen', // 全屏
'lineheight', // 行高
// 'help',
'charmap',
// 'paste',
'powerpaste',
'print',
'preview',
'hr',
'anchor',
'pagebreak',
'spellchecker',
'searchreplace',
'visualblocks',
'visualchars',
'insertdatetime',
'nonbreaking',
'autosave'
// 'fullpage',
// 'toc'
];

var plugins = [list.join(' ')];

export default plugins;